import React, { Component } from 'react';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import Config from 'data/prices/photodoc.json';
import Sets from 'data/prices/photodoc_sets.json';

import Banner from 'images/Photodoc.jpeg';


export default class PhotoServices extends Component {
  render() {
    return (
      <Row className="justify-content-md-center">
        <Col lg="8">
          <Card className="App-card">
            <Card.Header className="App-header">Цены на услуги "Фото на документы"</Card.Header>
            <Card.Body>
              <Card.Text>
                <Table striped bordered hover style={{marginBottom: 0}}>
                  <thead>
                    <tr>
                      <th>Услуга</th>
                      <th>Цена</th>
                    </tr>
                  </thead>
                  <tbody>
                  {Config.map(item => 
                    <tr>
                      <td>
                        {item.title}
                        {item.description && (
                          <span><br/>({item.description})</span>
                        )}
                      </td>
                      <td>{item.price}</td>
                    </tr>
                  )}
                  </tbody>
                </Table>
              </Card.Text>
            </Card.Body>
            <Card.Img variant="bottom" src={Banner}/>
          </Card>    
          <br/>   
        </Col>

        <Col xs md lg="2.5">
          <Card className="App-card">
            <Card.Header className="App-header">Комплекты</Card.Header>
            <Card.Body>
              <Card.Text>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Размер</th>
                      <th>Количество</th>
                    </tr>
                  </thead>
                  <tbody>
                  {Sets.map(item => 
                    <tr>
                      <td>{item.title}</td>
                      <td width="80px">{item.count}</td>
                    </tr>
                  )}
                  </tbody>
                </Table>
              </Card.Text>
            </Card.Body>
          </Card>
          <br/>
        </Col>
      </Row>
    )
  }
}