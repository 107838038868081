import React, { Component } from 'react';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import LaminationConfig from 'data/prices/lamination.json';
import StoreConfig from 'data/prices/store.json';
import ScanConfig from 'data/prices/scan.json';

import AlbumsImage from 'images/Albums.jpeg';


export default class OtherServices extends Component {
  render() {
    return (
    <Row className="justify-content-md-center">
      <Col>
          <Card className="App-card">
            <Card.Header className="App-header">Цены на услуги "Сканирование"</Card.Header>
            <Card.Body>
              <Card.Text>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Наименование и описание услуги</th>
                    <th>Цена</th>
                  </tr>
                </thead>
                <tbody>
                  {ScanConfig.map(item => 
                    <tr>
                      <td>
                        {item.title}
                        {item.description && (
                          <span><br/>{item.description}</span>
                        )}
                      </td>
                      <td>{item.price}</td>
                    </tr>
                  )}
                    <tr>
                      <td colSpan="2">
                      Максимальный размер сканируемого документа или фотографии - А4.
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                      При сканировании документа редактировать в Word (и других редакторах) его будет нельзя. 
                      Распознавание текста не делаем.
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                      При сканировании фотографий качество ухудшается, может изменяться цветопередача.
                      </td>
                    </tr>
                  </tbody>
                </Table>              
              </Card.Text>
            </Card.Body>
          </Card>
          <br/>
          <Card className="App-card">
            <Card.Header className="App-header">Также в продаже</Card.Header>
            <Card.Img variant="top" src={AlbumsImage}/>
            <Card.Body>
              <Card.Text className="text-center">Всегда в наличии широкой ассортимент альбомов и рамок для фотографий, а также лазерные диски для записи.</Card.Text>
            </Card.Body>
          </Card>
          <br/>
        </Col>
        <Col xs md={{span: 4}}>
        <Card className="App-card">
          <Card.Header className="App-header">Цены на услуги "Запись на носители"</Card.Header>
          <Card.Body>
            <Card.Text>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Наименование и описание услуги</th>
                    <th>Цена</th>
                  </tr>
                </thead>
                <tbody>
                {StoreConfig.map(item => 
                  <tr>
                    <td>
                      {item.title}
                      {item.description && (
                        <span><br/>{item.description}</span>
                      )}
                    </td>
                    <td>{item.price}</td>
                  </tr>
                )}
                  <tr>
                    <td colSpan="2">
                    Мы не переписываем фильмы, музыку, программы и прочий контент, защищенный законом об авторском праве.
                    </td>
                  </tr>
                  </tbody>
                </Table>
              </Card.Text>
            </Card.Body>
          </Card>
          <br/>
          <Card className="App-card">
            <Card.Header className="App-header">Цены на услуги "Ламинация"</Card.Header>
            <Card.Body>
              <Card.Text className="text-center">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Формат (размер)</th>
                    <th>Цена</th>
                  </tr>
                </thead>
                <tbody>
                {LaminationConfig.map(item => 
                  <tr>
                    <td>{item.format}</td>
                    <td>{item.price}</td>
                  </tr>
                )}
                  <tr>
                    <td colSpan="2">
                    Ламинация производится глянцевой пленкой плотностью 80-130 микрон. 
                    Во время ламинации документ нагревается до 130-170 градусов. 
                    Не ламинируем термобумагу, снимки УЗИ, ветхие документы, 
                    свидетельства о рождении, браке и пр.                                                                                    Работаем только с нашей пленкой.
                    </td>
                  </tr>
                  </tbody>
                </Table>              
              </Card.Text>
            </Card.Body>
          </Card>
          <br/>
        </Col>
      </Row>
    )
  }
}
