import React, { Component }  from 'react';

import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import Home from 'components/Home';
import HowTo from 'components/HowTo';
import PhotoRules from 'components/PhotoRules';
import PhotoServices from 'components/MakePhoto';
import SouvenirServices from 'components/Souvenirs';
import PrintDocServices from 'components/PrintDoc';
import PrintPhotoServices from 'components/PrintPhoto';
import DesignServices from 'components/Design';
import ImmortalServices from 'components/Immortal';
import OtherServices from 'components/Other';

import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'

// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

import './App.css';
import HeadBanner from 'images/Head.png';

class App extends Component {
  render() {
    return (
      <Router>
        <Container>
          <Image fluid src={HeadBanner} rounded/> <br/>
          <Navbar className="App-menu" expand="md">
          <Navbar.Brand as={Link} to='/'>Фото-Радуга</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <NavDropdown title="Наши услуги" id="basic-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/photodoc">Фото на документы</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item as={Link} to="/souvenir">Фото на сувенирах</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/printdoc">Распечатка</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/printphoto">Печать фотографий</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item as={Link} to="/immortal">Транспарант 9 мая</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item as={Link} to="/other">Другие услуги</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={Link} to='/howto'>Как сделать заказ</Nav.Link>
                <Nav.Link as={Link} to='/photorules'>Как получить качественные фотографии</Nav.Link>
                <Nav.Link as={Link} to='/'>Как нас найти</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>

          <Switch>
            <Route exact path='/'  component={ Home } />
            <Route exact path='/photodoc' component={ PhotoServices } />
            <Route exact path='/souvenir' component={ SouvenirServices } />
            <Route exact path='/printdoc' component={ PrintDocServices } />
            <Route exact path='/printphoto' component={ PrintPhotoServices } />
            <Route exact path='/design' component={ DesignServices } />
            <Route exact path='/immortal' component={ ImmortalServices } />
            <Route exact path='/other' component={ OtherServices } />
            <Route exact path='/howto' component={ HowTo } />
            <Route exact path='/photorules' component={ PhotoRules } />
          </Switch>
          <div className="note">© Фото-Радуга. Предложения на сайте не являются публичной офертой.</div>
        </Container>
      </Router>
    );
  }
}

export default App;