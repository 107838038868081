import React, { Component } from 'react';

import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';

import serviceConfig from 'data/prices/mugs.json';

import MugsBanner from 'images/Mugs.jpeg';
import TypesBanner from 'images/MugsTypes.jpeg';


export default class MugServices extends Component {
  render() {
    return (
      <Card className="App-card">
        <Card.Body>
          <Card.Text>
            <Card.Title>Цены на услуги "Фото на кружку"</Card.Title>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Разновидности кружек</th>
                  <th>Цена</th>
                </tr>
              </thead>
              <tbody>
              {serviceConfig.services.map(item => 
                <tr>
                  <td>
                    {item.title}
                  </td>
                  <td>{item.price}</td>
                </tr>
              )}
                </tbody>
              </Table>
              <Image rounded fluid src={TypesBanner}/>
            </Card.Text>
            <Card.Text>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Дополнительные услуги</th>
                  <th>Цена</th>
                </tr>
              </thead>
              <tbody>
              {serviceConfig.extra.map(item => 
                <tr>
                  <td>
                    {item.title}
                  </td>
                  <td>{item.price}</td>
                </tr>
              )}
                </tbody>
              </Table>
              <Image rounded fluid src={MugsBanner}/>
            </Card.Text>
            <Card.Text>
            *** При заказе кружки через группу ВК https://vk.com/foto_raduga_kursk 
            1 простой макет на каждую заказанную кружку выполняется <strong>бесплатно</strong>. 
            <br/>На кружке можно разместить 2-3 фото, добавить поздравительную надпись. 
            <br/>Также для заказа можно бесплатно воспользоваться макетами из альбомов группы.
          </Card.Text>
        </Card.Body>
      </Card>
    )
  }
}