import React from 'react';
import MugServices from './MugServices';
import ShirtServices from './ShirtServices';
import MagnetServices from './MagnetServices';
import PendantServices from './PendantServices';
import PillowServices from './PillowServices';
import PlateServices from './PlateServices';
import RugServices from './RugServices';
import SphereServices from './SphereServices';


export default class ServiceFactory {
    static build(id) {
        switch (id) {
            case 'mug':
                return <MugServices/>;
            case 'shirt':
                return <ShirtServices/>;
            case 'magnet':
                return <MagnetServices/>;
            case 'pendant':
                return <PendantServices/>;
            case 'pillow':
                return <PillowServices/>;
            case 'plate':
                return <PlateServices/>;
            case 'rug':
                return <RugServices/>;
            case 'sphere':
                return <SphereServices/>;
            default:
                return undefined;
        }
    }
}