import React, { Component } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab'

import ServiceFactory from './ServiceFactory';

import Config from 'data/services.json';


export default class SouvenirServices extends Component {
  render() {
    return (
      <Tab.Container 
        id="souvenir-tabs" 
        defaultActiveKey={ Config.services ? Config.services[0].id : "" }
        >
        <Row>
        <Col xs={{span: 12, order: 1}} sm={{span: 3, order: 2}}>
            <h5>Фото на сувенирах</h5>
            <Nav variant="pills" className="flex-column">
              {Config.services.map(item => 
                <Nav.Item>
                  <Nav.Link eventKey={item.id}>{item.title}</Nav.Link>
                </Nav.Item>
              )}
            </Nav>
          </Col>

          <Col xs={{order: 2}} sm={{order: 2}}>
            <Tab.Content>
              {Config.services.map(item => 
                <Tab.Pane eventKey={item.id}>
                  { ServiceFactory.build(item.id) }
                </Tab.Pane>
              )}
            </Tab.Content>
          </Col>

        </Row>
      </Tab.Container>
    )
  }
}