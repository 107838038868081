import React, { Component } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';

import Mail1Image from 'images/rules/Mail1.png';
import Mail2Image from 'images/rules/Mail2.png';
import OriginImage from 'images/rules/Origin.png';
import SaveOriginImage from 'images/rules/SaveOrigin.png';
import SaveOriginMobileImage from 'images/rules/SaveOriginMobile.png';
import VkAttachImage from 'images/rules/VkAttach.png';
import VkAttach2Image from 'images/rules/VkAttach2.png';
import VkAttachMobileImage from 'images/rules/VkAttachMobile.png';
import VkAttachMobile2Image from 'images/rules/VkAttachMobile2.png';
import VkAttachMobile3Image from 'images/rules/VkAttachMobile3.png';


export default class PhotoRules extends Component {
  render() {
    return (
      <Card className="App-card">
        <Card.Header className="App-header">Как получить качественные фотографии</Card.Header>
        <Card.Body>

          <Card.Text>
            Лучше всего предоставить оригиналы фотографий с фотоаппарата до того, как они были размещены в интернете. 
            <br/><br/>
            <Card body>
              Обычно оригинальные файлы имеют имена типа <i>DSCN1001, IMG_7389, IMGP4557, P004557, SAM_1871</i> и подобные им. 
              <br/>Такие файлы имеют большое разрешение и, как правило, высокую четкость. 
            </Card>
            <br/>А названия файлов типа <i>x_1d2602a6, y_10aaad8d, Q6QhlEmJ3eU, xXqv82Dz20A</i> говорят о том, что эти фотографии взяты из интернета. 
            <br/>После печати эти фотографии будут нечеткими, размытыми, в пикселях – квадратиках. </Card.Text>
          <Card.Text className="text-center"><Image rounded fluid src={Mail1Image}/></Card.Text>
          <Card.Text>
            При отправке файлов по электронной почте следует воспользоваться опцией «прикрепить файл» (обычно выглядит в виде скрепки) и выбрать вариант загрузки без сжатия. <br/><br/>
            <Card body>Пожалуйста, указывайте в теме письма <u>необходимый формат, тип бумаги и количество экземпляров.</u></Card>
          </Card.Text>
          <Card.Text className="text-center"><Image rounded fluid src={Mail2Image}/></Card.Text>

          <Card.Text>Если необходимо распечатать фотографии, выложенные в соцсетях, нужно правильно их сохранить.</Card.Text>
          <Card.Text className="text-center">
            <Row>
              <Col md="6"><Image rounded fluid src={OriginImage}/></Col>
              <Col><Image rounded fluid src={SaveOriginImage}/></Col>
            </Row>
          </Card.Text>
          <Card.Text>
            <b>На компьютере</b>: <br/>Нажмите на фотографию, чтобы она увеличилась на весь экран. В правом нижнем углу в меню «Еще» выберите пункт «Открыть оригинал». Фотография откроется в новом окне. 
            <br/>Необходимо нажать на фотографии правой кнопкой мыши, выбрать строку «Сохранить изображение как…» и выбрать место на компьютере, куда будет сохранена фотография. </Card.Text>
          <Card.Text className="text-center">
            <Row>
              <Col><Image rounded fluid src={SaveOriginMobileImage}/></Col>
              <Col><Image rounded fluid src={VkAttachMobile3Image}/></Col>
            </Row>
          </Card.Text>
          <Card.Text>
            <b>На смартфоне:</b><br/>Нажмите на фотографию, чтобы она увеличилась на весь экран. В правом верхнем углу в меню выберите пункт «Сохранить». Фотография будет сохранена на Вашем смартфоне. 
            <br/>Кликнув по уведомлению, Вы сможете совершить действия с сохраненной фотографией – например, открыть в «Галерее» или переместить.</Card.Text>
          <Card body>Если Вы делаете заказ через ВКонтакте, пожалуйста, загружайте фотографии как документы.</Card><br/>
          <Card.Text className="text-center">
            <Row>
              <Col md="6"><Image rounded fluid src={VkAttachImage}/></Col>
              <Col><Image rounded fluid src={VkAttach2Image}/></Col>
            </Row>
          </Card.Text>
          <Card.Text>
            <b>На компьютере: </b>
            <br/>Зайдите на страницу <a target="_blank" rel="noopener noreferrer" href="https://vk.com/foto_raduga_kursk"><b>НАШЕЙ ГРУППЫ</b></a>, в правом столбце нажмите «Написать сообщение». 
            <br/>В открывшемся окне внизу в меню «Еще» выберите «Документ». Если Вы общаетесь с группой или администратором через меню личных сообщений, нажмите на «скрепку» внизу слева и выберите «Документ». 
            <br/>Загрузите необходимые фотографии. </Card.Text>
          <Card.Text className="text-center">
            <Row>
              <Col><Image rounded fluid src={VkAttachMobileImage}/></Col>
              <Col><Image rounded fluid src={VkAttachMobile2Image}/></Col>
            </Row>
          </Card.Text>
          <Card.Text>
            <b>На смартфоне: </b>
            <br/>Зайдите на страницу <a target="_blank" rel="noopener noreferrer" href="https://vk.com/foto_raduga_kursk"><b>НАШЕЙ ГРУППЫ</b></a>, слева нажмите «Сообщение». 
            <br/>В открывшемся окне нажмите на «скрепку» внизу слева и выберите «Документ». 
            <br/>Загрузите необходимые фотографии. </Card.Text>
        </Card.Body>
      </Card>
    )
  }
}