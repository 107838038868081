import React, { Component } from 'react';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

import VkMessageImage from 'images/rules/VkMessage.png';
import VkMessageMobileImage from 'images/rules/VkMessageMobile.png';


export default class HowTo extends Component {
  render() {
    return (
    <Row className="justify-content-md-center">
        <Col>
            <Card className="App-card">
                <Card.Header className="App-header">Заказ можно сделать одним из следующих способов</Card.Header>
                <Card.Body>
                    <Card.Text>
                        <Card body>
                            <Card.Title>В салоне</Card.Title>
                            <Card.Text>
                                Посетить салон печати "Фото-радуга" лично в ТЦ "Перекрёсток", 1 этаж.
                            </Card.Text>
                            <Button size="sm" variant='warning' href="/">Подробнее</Button>        
                        </Card><br/>
                        <Card body>
                            <Card.Title>По e-mail</Card.Title>
                            <Card.Text>Отправить заказ на электронную почту <a target="_blank" rel="noopener noreferrer" href="mailto:foto-raduga-kursk@mail.ru?subject=Заказ">foto-raduga-kursk@mail.ru</a>, затем позвонить в салон по телефону +79103131017 и сообщить об отправленном заказе.</Card.Text>
                        </Card><br/>
                        <Card body>
                            <Card.Title>ВКонтакте</Card.Title>
                            <Card.Text>Написать сообщение сообществу в <a target="_blank" rel="noopener noreferrer" href="https://vk.com/foto_raduga_kursk">нашей группе ВК</a> или личное сообщение администратору группы <a target="_blank" rel="noopener noreferrer" href="https://vk.com/v_bugaenko">Веронике</a>.</Card.Text>
                            <Card.Text>
                                <Row>
                                    <Col><Image rounded fluid src={VkMessageImage}/><br/><br/></Col>
                                    <Col md="3"><Image rounded fluid src={VkMessageMobileImage}/></Col>
                                </Row>
                            </Card.Text>
                        </Card>
                    </Card.Text>
                </Card.Body>
            </Card>
            <br/>
        </Col>

        <Col lg="3">
            <Card className="App-card">
                <Card.Header className="App-header">Выдача готовых заказов</Card.Header>
                <Card.Body>
                    <Card.Text>
                        Только в салоне печати "Фото-радуга" в ТЦ "Перекрёсток". <br/> <br/>
                        <div className="App-text-important">Доставка на дом не производится.</div> <br/>
                    </Card.Text>
                </Card.Body>
            </Card><br/>
        </Col>
    </Row>
    )
  }
}