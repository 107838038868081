import React, { Component } from 'react';

import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';

import serviceConfig from 'data/prices/shirts.json';

import ShirtBannerWhite from 'images/ShirtBannerWhite.jpeg';
import ShirtBannerBlack from 'images/ShirtBannerBlack.jpeg';


export default class ShirtServices extends Component {
  render() {
    return (
      <Card className="App-card">
        <Card.Body>
          <Card.Text>
            <Card.Title>Цены на услуги "Фото на футболку"</Card.Title>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Услуга нанесения изображения на футболку<br/>(футболка включена в стоимость)</th>
                  <th>Цена</th>
                </tr>
              </thead>
              <tbody>
              {serviceConfig.services.map(item => 
                <tr>
                  <td>
                    {item.title}
                  </td>
                  <td>{item.price}</td>
                </tr>
              )}
                </tbody>
              </Table>
            </Card.Text>
            <Card.Text>
              <Image rounded fluid src={ShirtBannerWhite}/>
            </Card.Text>
            <Card.Text>
            На белые футболки изображение наносится методом сублимации - любая полноцветная картинка, фотографии, текст (за исключением запрещенных законом). 
            <br/>Футболки двухслойные: внутри хлопок, снаружи синтетика (данным способом изображения наносятся только на синтетические ткани). Изображение не прощупывается, не портится и не тускнеет при стирке.
            <br/><br/><u>Работаем только с нашими футболками. </u>
            <br/><br/>Размерный ряд: детские - от 26 до 40, мужские от 42 до 62, женские от 40 до 54. 
            <br/><br/><u>Максимальный размер изображения 25 х 35 см.</u>
            </Card.Text>
            <Card.Text>
              <Image rounded fluid src={ShirtBannerBlack}/>
            </Card.Text>
            <Card.Text>
            На черные футболки изображение наносится термопленкой - это может быть надпись, одноцветный рисунок с четким контуром. 
            <br/><br/>Доступные цвета пленки: <b>белый, красный, желтый, голубой. </b>
            <br/><br/>Футболки хлопковые, после стирки могут дать усадку на пол размера. 
            <br/>При длительной эксплуатации и частых стирках пленка может потрескаться. 
            <br/><br/><u>Работаем только с нашими футболками.  </u>
            <br/><br/>Размерный ряд: детские - 26-28, 30-32, 34-36, 38-40, унисекс от 42 до 56. 
            <br/><br/><u>Максимальный размер изображения 24 х 35 см.</u>
            </Card.Text>
          </Card.Body>
      </Card>
    )
  }
}