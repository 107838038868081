import React, { Component } from 'react';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';

import Banner1 from 'images/Immortal_1.jpg';
import Banner2 from 'images/Immortal_2.jpg';


export default class ImmortalServices extends Component {
  render() {
    return (
    <Row className="justify-content-md-center">
      <Col>
        <Card className="App-card">
          <Card.Header className="App-header">Транспаранты с фото героя войны</Card.Header>
          <Card.Body>
            <Row>
              <Col md="5">
                <Image fluid rounded src={Banner2} />
              </Col>
              <Col>
                <Card.Text>
                  Изготавливаем транспаранты для шествия на День Победы:<br/><br/><br/>
                  <Card body>
                    Основа - Лист ПВХ 31 х 43 см 
                    <br/>Ручка - Трубка ПВХ 40 - 42 см
                    <br/><br/>Фото, оформленное в рамку с личными данными, - струйная печать, ламинация.
                  </Card>
                  </Card.Text>
                  <br/>
                  <Card.Text>
                  <Card body>
                    Срок изготовления: 1 - 3 дня.
                  </Card>
                </Card.Text>
              </Col>
            </Row>
          </Card.Body>
          
        </Card>    
        <br/>
      </Col>

      <Col lg="4">
        <Card className="App-card" body>
          <Image fluid rounded src={Banner1} /><br/><br/>
          <h5>В стоимость входит:</h5>
            <ListGroup>
              <ListGroup.Item>Сканирование</ListGroup.Item>
              <ListGroup.Item>Оформление фото в рамку</ListGroup.Item>
              <ListGroup.Item>Печать</ListGroup.Item>
              <ListGroup.Item>Ламинация</ListGroup.Item>
              <ListGroup.Item>Монтаж на транспарант</ListGroup.Item>
            </ListGroup>
              <br/><div className="App-comment">Коррекция фото (при необходимости) оговаривается и оплачивается отдельно.</div>
          </Card>
      </Col>
      </Row>
    )
  }
}

/* <Card.Footer className="App-footer" style={{textAlign: "center"}}>Стоимость услуги - 500 руб.</Card.Footer> */