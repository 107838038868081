import React, { Component } from 'react';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';

import location from 'images/crossroad.jpg';
import icon_phone from 'icons/phone_grey.png';
import icon_email from 'icons/konvert_grey.png';
import icon_clock from 'icons/clock_grey.png';
import icon_vk from 'icons/vk_grey.png';

import SalonImage from 'images/Salon1.jpg';
import Salon2Image from 'images/Salon2.jpg';


export default class Home extends Component {
  render() {
    return (
    <Row className="justify-content-md-center">
        <Col xs={{order: 1}} sm={{ order: 1, span: 12}} md={{ order: 1, span: 12}} lg={{span: 8, order: 1}}>
            <Card className="App-card">
                <Card.Header className="App-header">Как нас найти</Card.Header>
                <Card.Body>
                    <Row>
                        <Col xs={{span: 'auto'}} md lg>
                            <Card.Text>
                                <strong>Адрес: </strong><br/> 
                                г. Курск, Ул. Дзержинского, д. 99А<br/>
                                ТЦ "Перекресток" <br/>
                                Ост. "50 лет Октября"<br/> <br/>
                                <strong>Режим работы: </strong> <br/>
                                <Image width='20px' src={icon_clock}/> ежедневно:  9.30 - 18.00 <br/>  
                            </Card.Text>
                        </Col>
                        <Col xs={{span: 12}} md lg>
                            <Card body>
                                <Image width='20px' src={icon_phone}/>+7-910-313-1017<br/>
                                <br/>
                                <Image style={{marginTop: "-5px"}} height='22px' src={icon_email}/> <a target="_blank" rel="noopener noreferrer" href="mailto:foto-raduga-kursk@mail.ru?subject=Вопрос по заказу">foto-raduga-kursk@mail.ru</a><br/> 
                                <br/>
                                <a target="_blank" rel="noopener noreferrer" href="https://vk.com/foto_raduga_kursk"><Image height='30px' src={icon_vk}/></a>
                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <br/>
            <iframe title="map" src="https://yandex.ru/map-widget/v1/?um=constructor%3Ad9e429cb46924dda68e9d77f1d3ca1c9f7506ae0b0fb6595e725d5d99cd1dffa&amp;source=constructor" width="100%" height="350" frameBorder="0"></iframe>
            <br/><br/>
        </Col>

        <Col xs={{order: 2}} sm={{ order: 2 }} md={{ order: 2 }} lg={{span: 3.5, order: 2}}>
            <Image rounded fluid src={location}/>
            <br/><br/>
            <Image rounded fluid src={SalonImage}/>
            <br/><br/>
            <Image rounded fluid src={Salon2Image}/>
            <br/><br/>
        </Col>
    </Row>
    )
  }
}