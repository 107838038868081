import React, { Component } from 'react';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';

import Config from 'data/prices/printphoto.json';

import Banner from 'images/PrintPhoto.jpeg';
import Fields from 'images/Fields.jpeg';


export default class PrintPhotoServices extends Component {
  render() {
    return (
      <Row className="justify-content-md-center">
        <Col xs>
          <Card className="App-card">
            <Card.Header className="App-header">Цены на услуги "Печать фотографий"</Card.Header>
            <Card.Body>
              <Card.Text>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Формат</th>
                      <th>Тип бумаги</th>
                      <th>Цена</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Config.map(item => item.availability && (
                        <tr>
                          <td>
                            {item.format}
                            {item.description && (
                              <span><br/>({item.description})</span>
                            )}
                          </td>
                          <td>{item.paper}</td>
                          <td>{item.price}</td>
                        </tr>
                      )
                    )}
                    </tbody>
                  </Table>
                  <div className="text-center"><Image rounded fluid src={Fields}/></div>
                </Card.Text>
                <Card.Text className="text-center">
                    При несоответствии пропорций Ваших фотографий выбранному формату изображение либо будет обрезаться, либо будет напечатано с белыми полями.       
                    <br/>Если при заказе Вы не указали свои предпочтения по этому вопросу - оператор действует на свое усмотрение.
                </Card.Text>   
              </Card.Body>
              <Card.Img variant="bottom" src={Banner}/>
            </Card>
            <br/>
          </Col>
          <Col md="4">
            <Card className="App-card">
              <Card.Header className="App-header">Оборудование и материалы</Card.Header>
              <Card.Body>
                <Card.Text>Печать фотографий производится на струйных 6-цветных фотопринтерах водорастворимыми чернилами. </Card.Text>
                <Card.Text>Для печати используется бумага Revcol, Lomond плотностью 170-210 гр/м2.</Card.Text>                                                                                        			
                <Card.Text>При печати края изображения обрезаются на 2-3 мм со всех сторон.</Card.Text>			
              </Card.Body>
            </Card>
            <br/>
            <Card className="App-card">
              <Card.Body>
                <Card.Text>
                При печати фото нестандартного размера стоимость рассчитывается так: цена следующего большего формата + 5 рублей. 
                <br/><br/>Например, необходимо напечатать фото размером 18 х 24 см, - берется стоимость фото формата 21 х 29,7 см (40 руб.) + 5 руб. = 45 руб.
                </Card.Text>
              </Card.Body>
            </Card>
            <br/>
            <Card className="App-card" body>
            Удаление эффекта <div style={{color: "red", fontWeight: "600"}}>"красных глаз"</div> - дополнительная платная услуга. <br/><br/>
            Заказывать данную услугу необходимо при сдаче фотографий в печать. <br/><br/>
            Если услуга не была заказана - претензии по "красным глазам" не приниматся. 
            <br/><br/>Стоимость услуги - 2 рубля за каждого человека на фото.
            </Card>
          </Col>
        </Row>
    )
  }
}

/*

            <br/>
            <Card className="App-card">
              <Card.Header className="App-header">Обратите внимание</Card.Header>
              <Card.Body>
                <Card.Text>
                При заказе фото с телефона, планшета и через мессенджеры стоимость каждой фотографии увеличивается на 2 рубля в связи с дополнительными затратами времени на поиск и передачу файла.
              </Card.Text>
            </Card.Body>
            </Card>

            */