import React, { Component } from 'react';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';

import Config from 'data/prices/printdocs.json';

import CopyImage from 'images/copy.png';


export default class PrintDocServices extends Component {
  render() {
    return (
    <Row className="justify-content-md-center">
      <Col xs={{order: 2}} md={{order: 1}}>
        <Card className="App-card">
          <Card.Header className="App-header">Цены на услуги "Печать документов"</Card.Header>
          <Card.Body>
            <Card.Text>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Наименование услуги и описание</th>
                    <th>Цена за 1 страницу</th>
                  </tr>
                </thead>
                <tbody>
                {Config.map(item => 
                  <tr>
                    <td>
                      {item.title}
                      {item.description && (
                        <div style={{fontSize: "0.8rem"}}><br/>{item.description}</div>
                      )}
                    </td>
                    <td>{item.price}</td>
                  </tr>
                )}
                  <tr>
                    <td colSpan="2">
                      Документы с печатью и / или подписью печатаем только в черно-белом варианте. 
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="1">
                    Копии детских дипломов и грамот за конкурсы печатаем в цвете только со штампом "копия" на обороте.
                    </td>
                    <td><Image fluid src={CopyImage}/></td>
                  </tr>
                  </tbody>
                </Table>
              </Card.Text>
            </Card.Body>
          </Card>
          <br/>
        </Col>
      </Row>
    )
  }
}

/*

<Col xs={{order: 1}} md={{span: 4, order: 2}}>
          <Card className="App-card">
            <Card.Header className="App-header">Обратите внимание</Card.Header>
            <Card.Body>
              <Card.Text className="text-center">
                При заказе распечатки с телефона, планшета и через мессенджеры стоимость каждой страницы увеличивается на 2 рубля в связи с дополнительными затратами времени на поиск и передачу файла.                
              </Card.Text>
            </Card.Body>
          </Card>
          <br/>
        </Col>

        */