import React, { Component } from 'react';

import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';

import Config from 'data/prices/spheres.json';

export default class SphereServices extends Component {
  render() {
    return (
      <div className="App-background">
        <h5>Цены на услуги "Фото на елочные игрушки"</h5><br/>
        <CardDeck>
          { Config.map(item => 
            <Card className="App-card-inside">
              <Card.Header className="App-header">{item.title}</Card.Header>
              <Card.Img variant="top" src={process.env.PUBLIC_URL + item.path} />
              
                {item.description && (
                  <Card.Body>
                    <Card.Text>
                    {item.description.map(line => 
                        <div style={{marginBottom: "10px"}}>{line}</div>
                      )}
                    </Card.Text>
                  </Card.Body>
                )}
              
              <Card.Footer className="App-footer">
                <small className="text-muted">{item.price}</small>
              </Card.Footer>
            </Card>
            )}
        </CardDeck>
      </div>
    )
  }
}