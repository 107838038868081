import React, { Component } from 'react';

import Card from 'react-bootstrap/Card';
import CardColumns from 'react-bootstrap/CardColumns';

import Config from 'data/prices/magnets.json';

export default class MagnetServices extends Component {
  render() {
    return (
      <div className="App-background">
        <h5>Цены на услуги "Фото на магниты"</h5><br/>
        <CardColumns>
          { Config.map(item => 
            <Card className="App-card-inside" style={{marginBottom: "20px"}}>
              <Card.Header className="App-header">{item.title}</Card.Header>
              <Card.Img variant="top" src={process.env.PUBLIC_URL + item.path} />
              
                {item.description && (
                  <Card.Body>
                    <Card.Text>
                      {item.description}
                    </Card.Text>
                  </Card.Body>
                )}
              
              <Card.Footer className="App-footer">
                <small className="text-muted">{item.price}</small>
              </Card.Footer>
            </Card>
          )}
        </CardColumns>
      </div>
    )
  }
}